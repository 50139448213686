<template>
  <div>
    <NuxtLoadingIndicator :duration="5000" color="repeating-linear-gradient(to right, #A77C37 0%, #CAB087 100%)" />
    <OrganismsGlobalTopBar v-if="topBarData" :is-enabled="!!topBarData" :text-color="topBarData.textColor" :background-color="topBarData.backgroundColor" :html="topBarData.text" :campaign="topBarData.campaign" ref="topBar"></OrganismsGlobalTopBar>
    <OrganismsGlobalAppHeader></OrganismsGlobalAppHeader>
    <div>
      <slot></slot>
    </div>
    <OrganismsGlobalAppFooter></OrganismsGlobalAppFooter>
    <MoleculesGlobalVideoPlayer />
  </div>
</template>
<script setup>

/*const topBarData = {
  text: '',
  textColor: 'white',
  backgroundColor: 'rgb(167 124 55 / 1)'
}*/

const topBarData = (await useApiFetch(`/api/topBar?languageId=1`)).topBar;

</script>